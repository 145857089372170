* {
  font-family: 'Inter', sans-serif;
}
@import './eclaim-style/input';
@import './eclaim-style/text_color';
@import './eclaim-style/table';
@import './eclaim-style/upload';
@import './eclaim-style/button';
@import './eclaim-style/badge';
@import './eclaim-style/form_control';
@import './eclaim-style/layout_custom';
@import './eclaim-style/menu_sidebar';

nb-layout.window-mode .layout nb-layout-header nav {
  border-bottom: 1px solid #ccc;
}
[nbInput].status-basic {
  background-color: #fff;
}
.logo-icon {
  width: 120px;
  height: auto !important;
}
.captcha-block {
  .fa-retweet {
    font-size: 20px !important;
  }
}
.ant-picker {
  border-radius: 8px !important;
  &.ant-picker-range {
    border-radius: 8px !important;
  }
}
.ecl-pagination {
  text-align: right;
  margin-top: 15px;
  .ant-table-pagination.ant-pagination {
    // justify-content: flex-start;
  }
  .ant-pagination-item a {
    text-decoration: none !important;
  }
  .ant-pagination-item-active a {
    color: #1890ff !important;
  }
}
ngx-status-display {
  span {
    // color: #fa0;
    // background: #fff7e6;
    padding: 5px 10px;
    border-radius: 10px;
  }
  &.WAITING {
    span {
      color: #fa0;
      background: #faf2e1;
    }
  }
  &.DRAFT {
    span {
      color: rgb(0, 0, 0);
      b {
        color: rgb(0, 0, 0);
      }
      background: #dcdcdc;
    }
  }
  &.APPROVED {
    span {
      color: #00d68f;
      b {
        color: #00d68f;
      }
      background: #effffa;
    }
  }
  &.REJECTED {
    span {
      color: #ff3d71;
      b {
        color: #ff3d71;
      }
      background: #ffedf3;
    }
  }
  &.HALF_DONE {
    span {
      color: #008cff;
      b {
        color: #008cff;
      }
      background: #e2f2ff;
    }
  }
  &.CLOSED {
    span {
      color: red;
      b {
        color: red;
      }
      background: #ffcbcb;
    }
  }
}
