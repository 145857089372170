.menu-sidebar {
  background-color: #0a2926;
  .scrollable {
    padding-top: 0 !important;
  }
  .main-container {
    background: #0a2926 !important;
    padding: 10px;
    .menu-items {
      background-color: #0a2926 !important;
      padding: 0;
      .menu-icon {
        color: white !important;
      }
      .menu-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
      .menu-item {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        border: none;
        margin-top: 5px;
        margin-bottom: 5px;
        position: relative;
        a {
          padding-left: 30px;
        }
        > .menu-items {
          padding: 0;
          // margin: 0;
          .menu-item {
            border: none;
            margin-top: 5px;
            margin-bottom: 5px;
            a {
              padding-left: 30px;
            }
          }
        }
      }
      //lvl 2
      .menu-item > .menu-items > .menu-item::before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #99f6e0;
        position: absolute;
        left: 15px;
        top: 15px;
      }

      //lvel 3
      > .menu-item > .menu-items > .menu-item > .menu-items {
        .menu-item {
          border: none;
          margin-top: 5px;
          margin-bottom: 5px;
          .menu-title {
            margin-left: 25px;
            position: relative;
          }
        }
      }
      .menu-item > .menu-items > .menu-item > .menu-items > .menu-item::before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #99f6e0;
        position: absolute;
        left: 30px;
      }

      .menu-item {
        .expand-state {
          color: white !important;
        }
        .menu-icon {
          color: white !important;
        }
        a {
          color: white;
          padding: 8px 12px;
          &:hover {
            // color: rgb(200, 200, 200);
            color: white;
          }
        }
        a.active {
          color: white;
          background-color: #0e9384;
          border-radius: 5px;
          &:hover {
            // color: rgb(200, 200, 200);
            color: white;
            background-color: #0e9384;
          }
        }
      }
    }
  }
}
