.btn {
  border-radius: 8px;
  // border: 1px solid #ffc107;
  // background: #ffc107;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  // color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 142.857% */

  &.btn-outline-secondary {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background: #fff;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    color: #344054;

    &:hover {
      border: 1px solid #d0d5dd;
      background: #fff;
      color: #344054;
      i.fa {
        color: #344054;
      }
    }
    i.fa {
      color: #344054;
    }
  }
  &.btn-primary {
    border-radius: 8px;
    border: 1px solid #0e9384;
    background: #0e9384;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;

    &:hover {
      border: 1px solid #0e9384;
      background: #0e9384;
      color: #fff;
      i.fa {
        color: #fff;
      }
    }
    i.fa {
      color: #fff;
    }
  }
  &.btn-warning {
    border-radius: 8px;
    border: 1px solid #ffc107;
    background: #ffc107;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;
    &:hover {
      border: 1px solid #ffc107;
      background: #ffc107;
      color: #fff;
      i.fa {
        color: #fff;
      }
    }
    i.fa {
      color: #fff;
    }
  }
  &.btn-info {
    border-radius: 8px;
    border: 1px solid #2e90fa;
    background: #2e90fa;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #fff;
    &:hover {
      border: 1px solid #2e90fa;
      background: #007bff;
      color: #fff;
      i.fa {
        color: #fff;
      }
    }
    i.fa {
      color: #fff;
    }
  }
}

.appearance-filled {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 142.857% */
  border-radius: 8px !important;
  &.status-danger {
    color: #fff !important;
    // background-color: #dc3545 !important;
    // border-color: #dc3545 !important;
    border-radius: 8px;
    border: 1px solid #d92d20 !important;
    background: #d92d20 !important;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  &.status-success {
    // border-radius: 8px;
    border: 1px solid #0e9384 !important;
    background: #0e9384 !important;
    box-shadow: 0 1px 2px #1018280d !important;
    color: #fff;
    // font-family: Inter;
    // font-size: 14px;
    // font-style: normal;
    // font-weight: 600;
    // line-height: 14px;
    &.btn-disabled {
      color: #bbbbbb !important;
      cursor: not-allowed;
    }
  }
}
.appearance-ghost {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 142.857% */
  border-radius: 8px !important;
  &.status-info {
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    box-shadow: none !important;
    border-color: #0095ff !important;
  }
}
.appearance-hero {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 142.857% */
  border-radius: 8px !important;
  &.status-success {
    // border-radius: 8px;
    border: 1px solid #0e9384 !important;
    background: #0e9384 !important;
    box-shadow: 0 1px 2px #1018280d !important;
    color: #fff;
    // font-family: Inter;
    // font-size: 14px;
    // font-style: normal;
    // font-weight: 600;
    // line-height: 14px;
    &.btn-disabled {
      color: #bbbbbb !important;
      cursor: not-allowed;
    }
  }
  &.status-primary {
    color: #fff;
    background-color: #2e90fa !important;
    background: #2e90fa !important;
    border-color: #2e90fa !important;
    border: 1px solid #2e90fa !important;
    &.btn-disabled {
      color: #bbbbbb !important;
      cursor: not-allowed;
    }
  }
}

.btn,
.appearance-hero,
.appearance-ghost,
.appearance-filled {
  line-height: 18px; /* 142.857% */
  font-size: 14px;
}
