.form-control {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: #667085;
  /* Text sm/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding: 5px !important;
  &.custom-placeholder {
    &::placeholder {
      color: #d4d4d4 !important;
    }
    &::-webkit-input-placeholder,
    &::-ms-input-placeholder,
    &::-moz-placeholder {
      color: #d4d4d4 !important;
    }
  }
}
