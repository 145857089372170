.wrap-table-eclaim {
}
.table-eclaim {
  // width: auto !i
  .ant-table-content {
    overflow-x: scroll;
    min-width: 100%;
    table {
      width: max-content;
      min-width: 100%;
      width: 100%;
      overflow-x: scroll;
    }
  }

  .pointer {
    cursor: pointer;
  }
  thead,
  tbody {
    border: 1px solid #eaecf0;
  }
  thead {
    th {
      border-left: 1px solid #eaecf0;
      border-bottom: 1px solid #eaecf0;
      background: #f9fafb;

      color: #475467;
      /* Text sm/Semibold */
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 142.857% */
      .ant-table-column-title {
        color: #475467;
        /* Text sm/Semibold */
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 142.857% */
      }
      .ant-table-column-sorter {
        font-size: 12px;
        line-height: 12px; /* 142.857% */
      }
      &.col-key-status {
        padding-right: 10px !important;
      }
    }
    // th:last-child {
    //   border-left: 1px solid #eaecf0;
    // }
  }
  tbody {
    tr {
      border-bottom: 1px solid #eaecf0;
      // background: #fcfcfd;
      // background: yellow !important;
      td {
        color: #475467;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 142.857% */
        background: transparent;
      }
      // td:last-child {
      //   border-left: 1px solid #eaecf0;
      // }
    }
    tr:nth-child(even) {
      // background: #fff;
      td {
        background: #fcfcfd !important;
      }
    }
  }

  th,
  td {
    padding: 14px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    // min-width: 30px;
    // max-width: 200px;
  }
  .col-key-id {
    width: 60px !important;
    padding: 0 5px !important;
  }
  .col-key-action {
    width: 65px !important;
    min-width: 65px !important;
    padding: 0 0px 0 5px !important;
    border-left: 1px solid #eaecf0 !important;
    img {
      margin-right: 5px !important;
      width: 16px !important;
      height: 16px !important;
    }
  }
  .ant-table-pagination.ant-pagination {
    // justify-content: flex-start;
  }
  .ant-pagination-item a {
    text-decoration: none !important;
  }
  .ant-pagination-item-active a {
    color: #1890ff;
  }
}
table.eclaim-table {
  .pointer {
    cursor: pointer;
  }
  thead,
  tbody {
    border: 1px solid #eaecf0 !important;
    border-color: #eaecf0 !important;
  }
  thead {
    th {
      border-left: 1px solid #eaecf0 !important;
      border-bottom: 1px solid #eaecf0 !important;
      background: #f9fafb !important;
      background-color: #f9fafb !important;

      color: #475467 !important;
      /* Text sm/Semibold */
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 142.857% */
      .ant-table-column-title {
        color: #475467 !important;
        /* Text sm/Semibold */
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 142.857% */
      }
      .ant-table-column-sorter {
        font-size: 12px;
        line-height: 12px; /* 142.857% */
      }
      &.col-key-status {
        padding-right: 10px !important;
      }
    }
    // th:last-child {
    //   border-left: 1px solid #eaecf0;
    // }
  }
  tbody {
    tr {
      border-bottom: 1px solid #eaecf0 !important;
      border-color: #eaecf0 !important;
      // background: #fcfcfd;
      // background: yellow !important;
      td {
        color: #475467 !important;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 142.857% */
        background: transparent;
      }
      // td:last-child {
      //   border-left: 1px solid #eaecf0;
      // }
    }
    tr:nth-child(even) {
      // background: #fff;
      td {
        background: #fcfcfd !important;
        background-color: #fcfcfd !important;
      }
    }
  }

  th,
  td {
    padding: 12px;
    padding-left: 5px;
    padding-right: 5px;
    // min-width: 30px;
    // max-width: 200px;
  }
}
