.ng-select {
  padding-bottom: 1.25em;
  .ng-value-container {
    padding-left: 10px !important;
  }
}
.ng-select.ng-select-disabled .ng-select-container:after {
  border-bottom-color: transparent;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 1px;
  background-repeat: repeat-x;
}
.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
  color: rgba(0, 0, 0, 0.38);
}
.ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow,
.ng-select.ng-select-disabled .ng-clear-wrapper {
  color: rgba(0, 0, 0, 0.38);
}
.ng-select.ng-select-focused .ng-select-container:after {
  border-color: #3f51b5;
  border-width: 2px;
}
.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,
.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after {
  border-color: #3f51b5;
  border-width: 2px;
}
.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  color: #3f51b5;
}
.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: #3f51b5;
}
.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-filtered .ng-select-container .ng-placeholder {
  display: initial;
}
.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-opened .ng-placeholder {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
}
.ng-select .ng-select-container {
  color: rgba(0, 0, 0, 0.87);
  align-items: baseline;
  min-height: 51.5px;
}
.ng-select .ng-select-container:after {
  border-bottom: thin solid rgba(0, 0, 0, 0.42);
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  transition: border-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}
.ng-select .ng-select-container.ng-appearance-outline {
  padding: 0 0.5em;
  min-height: 60px;
}
.ng-select .ng-select-container.ng-appearance-outline:after {
  border: solid 1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  height: calc(100% - 0.5em);
  pointer-events: none;
  transition: border-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.ng-select .ng-select-container.ng-appearance-outline:hover:after {
  border-color: rgba(0, 0, 0, 0.87);
  border-width: 2px;
}
.ng-select .ng-select-container.ng-appearance-outline .ng-placeholder {
  padding: 0 0.25em;
  background-color: #fff;
  z-index: 1;
}
.ng-select .ng-select-container.ng-appearance-outline .ng-value {
  padding-left: 0.25em;
}
.ng-select .ng-select-container .ng-value-container {
  align-items: stretch;
  padding: 0.4375em 0;
  border-top: 0.84375em solid transparent;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
  transform-origin: left 0;
  transition:
    transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}
[dir='rtl'] .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  transform-origin: right 0;
}
.ng-select .ng-select-container .ng-value-container .ng-input {
  bottom: 0.4375em;
}
.ng-select.ng-select-single .ng-select-container.ng-appearance-outline .ng-arrow-wrapper {
  bottom: 17px;
}
.ng-select.ng-select-single .ng-select-container.ng-appearance-outline .ng-clear-wrapper {
  bottom: 14px;
}
.ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
  align-self: flex-end;
  bottom: 6px;
}
.ng-select.ng-select-single .ng-select-container .ng-clear-wrapper {
  align-self: flex-end;
  bottom: 7px;
}
.ng-select.ng-select-multiple.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:after,
.ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:hover:after {
  background-image: none;
  border: dotted 1px rgba(0, 0, 0, 0.12);
}
.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline.ng-has-value .ng-arrow-wrapper,
.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline.ng-has-value .ng-clear-wrapper {
  border-top: none;
}
.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline .ng-arrow-wrapper {
  top: 0;
}
.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline .ng-clear-wrapper {
  top: 4px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #3f51b5;
  border-radius: 2px;
  color: #fff;
  padding: 2px 5px;
  margin: 0 0.4375em 0.4375em 0;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin: 0 0 0.4375em 0.4375em;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-size: 14px;
  font-weight: 500;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  color: rgba(69, 69, 69, 0.54);
  padding-right: 5px;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  padding-left: 5px;
  padding-right: 0;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  color: rgba(255, 255, 255, 0.87);
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  line-height: 1.375em;
}
.ng-select.ng-select-multiple .ng-select-container.ng-has-value {
  align-items: center;
}
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
  padding-bottom: 0;
  padding-top: 0.1875em;
}
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
  // border-top: 0.84375em solid transparent;
}
.ng-select .ng-clear-wrapper {
  color: rgba(0, 0, 0, 0.54);
}
.ng-select .ng-clear-wrapper:hover {
  color: rgba(0, 0, 0, 0.87);
}
.ng-select .ng-arrow-wrapper {
  bottom: 2px;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.54);
}
.ng-select .ng-spinner-zone {
  top: 3px;
}
.ng-dropdown-panel {
  background: #fff;
  left: 0;
}
.ng-dropdown-panel.ng-select-top {
  bottom: calc(100% - 0.84375em);
  box-shadow:
    0 -5px 5px -3px rgba(0, 0, 0, 0.2),
    0 -8px 10px 1px rgba(0, 0, 0, 0.14),
    0 -3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ng-dropdown-panel.ng-select-right {
  left: 100%;
  top: calc(0% + 0.84375em);
  box-shadow:
    0 -5px 5px -3px rgba(0, 0, 0, 0.2),
    0 -8px 10px 1px rgba(0, 0, 0, 0.14),
    0 -3px 14px 2px rgba(0, 0, 0, 0.12);
  margin-left: 4px;
}
.ng-dropdown-panel.ng-select-bottom {
  top: calc(100% - 1.25em);
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ng-dropdown-panel.ng-select-left {
  left: calc(-100% - 4px);
  top: calc(0% + 0.84375em);
  box-shadow:
    0 -5px 5px -3px rgba(0, 0, 0, 0.2),
    0 -8px 10px 1px rgba(0, 0, 0, 0.14),
    0 -3px 14px 2px rgba(0, 0, 0, 0.12);
}
.ng-dropdown-panel.multiple .ng-option.selected {
  background: #fff;
}
.ng-dropdown-panel.multiple .ng-option.marked {
  background: rgba(0, 0, 0, 0.04);
}
.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 16px;
  line-height: 3em;
  min-height: 3em;
}
.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0 16px;
  line-height: 3em;
  min-height: 3em;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  cursor: pointer;
  // line-height: 3em;
  // height: 3em;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background: rgba(0, 0, 0, 0.04);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  background: rgba(0, 0, 0, 0.12);
  color: #3f51b5;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  // line-height: 3em;
  // min-height: 3em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 16px;
  text-decoration: none;
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
}
[dir='rtl'] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  text-align: right;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: rgba(0, 0, 0, 0.12);
  color: #3f51b5;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 32px;
}
[dir='rtl'] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-right: 32px;
  padding-left: 0;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-right: 5px;
  font-size: 80%;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.38);
}
[dir='rtl'] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-left: 5px;
  padding-right: 0;
}

.ng-select.ng-select-opened > .ng-select-container {
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9;
}
.ng-select.ng-select-opened > .ng-select-container:hover {
  box-shadow: none;
}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}
.ng-select.ng-select-opened > .ng-select-container .ng-arrow:hover {
  border-color: transparent transparent #333;
}
.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.ng-select.ng-select-opened.ng-select-right > .ng-select-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ng-select.ng-select-opened.ng-select-left > .ng-select-container {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #007eff;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
}
.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f9f9f9;
}
.ng-select .ng-has-value .ng-placeholder {
  display: none;
}
.ng-select .ng-select-container {
  color: #333;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  min-height: 36px;
  align-items: center;
}
.ng-select .ng-select-container:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}
.ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-left: 10px;
}
[dir='rtl'] .ng-select .ng-select-container .ng-value-container {
  padding-right: 10px;
  padding-left: 0;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #999;
}
.ng-select.ng-select-single .ng-select-container {
  height: 32px;
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 5px;
  left: 0;
  padding-left: 10px;
  padding-right: 50px;
}
[dir='rtl'] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 10px;
  padding-left: 50px;
}
.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
}
.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0 5px;
  color: #475467;
}
.ng-select-disabled .ng-select-container .ng-value-container .ng-value .ng-value-label {
  color: #475467;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 5px;
  padding-left: 7px;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-right: 7px;
  padding-left: 0;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 0.9em;
  margin-bottom: 5px;
  color: #333;
  background-color: #ebf5ff;
  border-radius: 2px;
  margin-right: 5px;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-right: 0;
  margin-left: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #f9f9f9;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 5px;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 0;
  padding-right: 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 1px 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: inline-block;
  padding: 1px 5px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: #d1e8ff;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #b8dbff;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-left: 1px solid #b8dbff;
  border-right: none;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 1px solid #b8dbff;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 0;
  border-right: 1px solid #b8dbff;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0 3px 3px;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 3px 3px 0;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input {
  color: #000;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
}
[dir='rtl'] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-right: 3px;
  padding-left: 0;
}
.ng-select .ng-clear-wrapper {
  color: #999;
}
.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #d0021b;
}
.ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}
[dir='rtl'] .ng-select .ng-spinner-zone {
  padding: 5px 0 0 5px;
}
.ng-select .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
}
[dir='rtl'] .ng-select .ng-arrow-wrapper {
  padding-left: 5px;
  padding-right: 0;
}
.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: #666;
}
.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}
.ng-dropdown-panel {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;
}
.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-color: #e6e6e6;
  margin-bottom: -1px;
}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.ng-dropdown-panel.ng-select-right {
  left: 100%;
  top: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-color: #e6e6e6;
  margin-bottom: -1px;
}
.ng-dropdown-panel.ng-select-right .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 4px;
}
.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: #e6e6e6;
  margin-top: -1px;
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ng-dropdown-panel.ng-select-left {
  left: -100%;
  top: 0;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-color: #e6e6e6;
  margin-bottom: -1px;
}
.ng-dropdown-panel.ng-select-left .ng-dropdown-panel-items .ng-option:first-child {
  border-top-left-radius: 4px;
}
.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #ccc;
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #ccc;
  padding: 5px 7px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: 8px 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #f5faff;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  color: rgba(0, 0, 0, 0.54);
  background-color: #ebf5ff;
  font-weight: 600;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 10px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #333;
  background-color: #ebf5ff;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 600;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f5faff;
  color: #333;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #ccc;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px;
}
[dir='rtl'] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-right: 22px;
  padding-left: 0;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: 5px;
}
[dir='rtl'] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-left: 5px;
  padding-right: 0;
}
[dir='rtl'] .ng-dropdown-panel {
  direction: rtl;
  text-align: right;
}
