.text-primary {
  color: #0e9384;
}
.text-bold,
.bold {
  font-weight: bold;
}
.text-black {
  color: black;
}
