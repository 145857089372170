.badge {
  padding: 2px 8px;
  align-items: center;
  border-radius: 16px;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  &.badge-success {
    border: 1px solid #abefc6 !important;
    background: #ecfdf3 !important;
    color: #067647 !important;
  }
}
nb-toggle.status-basic .toggle {
  background-color: rgb(143 155 179 / 60%);
  // border-color: var(--toggle-basic-border-color);
}
