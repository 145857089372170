@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import './ng-select.material.theme.scss';
// themes - our custom or/and out of the box themes
@import './themes';
// framework component themes (styles tied to theme variables)
@import 'node_modules/@nebular/theme/styles/globals';
@import 'node_modules/@nebular/auth/styles/globals';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/grid';
// loading progress bar theme
@import './pace.theme';
@import './layout';
@import './overrides';
// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();
  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();
  @include nb-overrides();
}

.error-message {
  color: red;
}

.nb-theme-default nb-layout .layout .layout-container .content .columns nb-layout-column {
  padding: 0.75rem;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

input,
textarea {
  &.ng-invalid:not(.ng-pristine) {
    border-color: red;
  }
}

input-num.ng-invalid:not(.ng-pristine) {
  input {
    border-color: red;
  }
}

ngx-search-select,
ng-select {
  &.ng-invalid:not(.ng-pristine) {
    .ng-select-container::after {
      border-color: red;
      border-width: 2px;
    }
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 42px;
}

::-webkit-scrollbar {
  height: 0.75rem !important;
  width: 0.75rem !important;
}

.nb-theme-default nb-layout ::-webkit-scrollbar-thumb {
  background: #b0b2b5;
}

.nb-theme-default nb-layout ::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.scroll-table-container {
  position: relative;
  overflow-x: auto;
  table {
    min-width: 100%;
    width: max-content;
  }
  .col-type {
    min-width: 140px;
    width: max-content;
  }
  .col-po-number {
    min-width: 165px;
    width: max-content;
  }
  .col-group-approval {
    width: 100px;
  }
}

mat-dialog-container {
  overflow: hidden !important;
}
